import styles from "./Top.module.css"

import React, {useEffect, useRef, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import {RequestTopStats, TopStateUser} from '../../../app/slices/topSlice'
import {Button} from '../../../components/view/Button'

interface Content {
    [key: string]: {
        text: string
    }
}

function GetUserName(user: TopStateUser) {
    const invisibleSymbol = 'ᅠ ᅠ '

    if (!user.firstName.includes(invisibleSymbol) && user.firstName.trim()) return user.firstName.trim()
    if (user.username.trim()) return `@${user.username}`
    
    return 'unknown'
}

export default function Top() {
    const dispatch = useAppDispatch()
    const data = useAppSelector((state) => state.topStats)
    const [currentCategory, setCurrentCategory] = useState('balanceMe4ton')
    const hasMounted = useRef(false);

    const content: Content = {
        balanceMe4ton: {
            text: "Balance Me4ton"
        },
        balanceNe4to: {
            text: "Balance Ne4to",
        },
        balanceE4: {
            text: "Balance E4",
        },
        incomeMe4ton: {
            text: "Income Me4ton",
        },
        incomeE4: {
            text: "Income E4",
        },
    }

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true;
            dispatch(RequestTopStats())
        }
    }, []);

    return (
        <div className={styles.topContainer}>
            <div className={styles.container}>
                <div className={styles.header}>
                    Категории
                </div>
                <div className={styles.categoryContainer}>
                    {
                        Object.keys(content).map((category) => (
                            <div key={category}>
                                <Button text={content[category].text} onClick={() => setCurrentCategory(category)}
                                        selected={currentCategory === category}></Button>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={styles.dataContainer}>
                {
                    // @ts-ignore
                    data[currentCategory].map((user) => (
                        <div key={user.id + '_' + user.place}>
                        {
                            // @ts-ignore
                            user.place > data[currentCategory].length && (
                                <div className={styles.placeSpacer}>. . .</div>
                            )
                        }
                            <div className={styles.user} >
                                <div className={styles.userPlace}>{user.place}</div>
                                <div className={styles.userName}>{GetUserName(user)}</div>
                                <div className={styles.userValue}>{user.value}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
        // <div className={styles.container}>
        //     <div className={styles.row}>
        //         <TonConnectButton className={'pinned-right'}/>
        //     </div>
        //
        //     <div className={styles.row}>
        //         <HorizontalStatView title={"Баланс ne4To"} text={data.balanceNe4to}/>
        //     </div>
        //
        //     <div className={styles.row}>
        //         <HorizontalStatView title={"Баланс me4ton"} text={data.balanceMe4ton}/>
        //     </div>
        //
        //     <div className={styles.row}>
        //         <HorizontalStatView title={"Баланс E4"} text={data.balanceE4}/>
        //     </div>
        //
        //     <div className={styles.row}>
        //         <div className={styles.nftContainer}>
        //             {
        //                 data.NFTs.map(
        //                     (item, i) => (
        //                         <NFTView nft={item} key={`nft${i}`}
        //                         />
        //                     )
        //                 )
        //             }
        //         </div>
        //     </div>
        // </div>
    )
}
