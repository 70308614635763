const VendorShop = {
    title:      "Из магазина",
    type:       "shop",
} as const

const VendorAvito = {
    title:      "Б/У с Авито",
    type:       "tradePlatform",
} as const

const VendorAliexpress = {
    title:      "С Aliexpress",
    type:       "tradePlatformChina",
} as const


const VendorFriend = {
    title:      "С рук у друга",
    type:       "used",
} as const

export const Vendor = {
    shop:   VendorShop,
    ali:    VendorAliexpress,
    avito:  VendorAvito,
    friend: VendorFriend
} as const

export type Vendor = typeof Vendor[keyof typeof Vendor]
