import {createSlice} from '@reduxjs/toolkit'
import {ToTitleCase} from '../functions'
import {AppThunk} from '../store'
import {DataUpdateAction, SettingsUpdateAction} from '../../model/types'
import {sendEvent} from '../../features/socket/socketSlice'
import {CurrencyObject} from '../../model/interfaces'

const allowedWeather = ['clear', 'cloudy', 'rain']
export interface LocationState {
    location:               string
    locationId:             number
    nextLocation:           string

    time:                   string
    weather:                string
    temp:                   number
    temp_out:               number

    upgradeAvailable:       boolean
    upgradePrice:           CurrencyObject
    minBalanceAfterUpgrade: number

    equipment:              LocationEquipment[]
}

export interface LocationEquipment {
    id:                     string
    name:                   string
    purchased:              boolean
    active:                 boolean
    price:                  CurrencyObject
    consumption:            CurrencyObject
    description: {
        info:               string
        active?:            string
        disabled?:          string
    }
}

const initialState: LocationState = {
    location:               '',
    locationId:             1,
    nextLocation:           '',

    time:                   '00:00',
    weather:                'clear',
    temp:                   20,
    temp_out:               20,

    upgradeAvailable:       false,
    upgradePrice: {
        E4:                 0,
        me4ton:             0,
        ne4to:              0,
    },
    minBalanceAfterUpgrade: 0,

    equipment:              [],
}

export const locationSlice = createSlice({
    name: 'locationSlice',
    initialState,
    reducers: {
        updateLocationSettings: (state, action: SettingsUpdateAction) => {
            const settings = action.payload.settings

            state.location = ToTitleCase(settings.title)

            state.nextLocation = ToTitleCase(settings.titlenext)
            state.upgradeAvailable = settings.nextunlock === 1
            state.upgradePrice = {
                E4:             0,
                me4ton:         settings.pricelocme4,
                ne4to:          settings.pricelocne4,
            }
            state.minBalanceAfterUpgrade = +settings.limlocbuy

        },
        updateLocationData: (state, action: DataUpdateAction) => {
            const data = action.payload.data
            state.locationId = +data.loc

            state.time = data.location?.time
            state.weather = allowedWeather.includes(data.location?.weather) ? data.location?.weather : 'clear'
            state.temp = +data.location?.temperature
            state.temp_out = +data.location?.temperature_out

            state.equipment = data.location.equipments.map(el => {
                let result: LocationEquipment = {
                    name: ToTitleCase(el.name),
                    description: {
                        info: el.description
                    },
                    id: el.name,
                    purchased: el.purchased === 1,
                    active: el.active === 1,
                    price: {
                        E4:     +el.price.e4,
                        me4ton: +el.price.me4ton,
                        ne4to:  +el.price.ne4to,
                    },
                    consumption: {
                        E4:     +el.consumption.e4,
                        me4ton: +el.consumption.me4ton,
                        ne4to:  +el.consumption.ne4to,
                    }
                }

                if (el.name === 'window') {
                    result.name = 'Окно'
                    result.description.active = 'Открыто'
                    result.description.disabled = 'Закрыто'
                } else if (el.name === 'blinds') {
                    result.name = 'Жалюзи'
                    result.description.active = 'Закрыты'
                    result.description.disabled = 'Открыты'
                } else if (el.name === 'conditioner') {
                    result.name = 'Кондиционер'
                } else if (el.name === 'router') {
                    result.name = 'Маршрутизатор'
                } else if (el.name === 'watchdog') {
                    result.name = 'Вотчдог'
                }

                return result
            })
        }
    }
})

export const UpgradeLocation = (currentLocationId: number): AppThunk => async (dispatch) => {
    const eventName = 'buy_loc';

    dispatch(sendEvent({
        event: eventName,
        message: {
            loc: ++currentLocationId
        }
    }))
}

export const BuyEquipment = (item: string): AppThunk => async (dispatch) => {
    const eventName = `buy_${item}`;

    dispatch(sendEvent({
        event: eventName,
        message: {}
    }))
}

export const SwitchEquipment = (item: string, requiredActiveState: boolean): AppThunk => async (dispatch) => {
    const eventName = `set_${item}`;

    const newState = requiredActiveState ? 'on' : 'off'
    dispatch(sendEvent({
        event: eventName,
        message: {
            works: newState
        }
    }))
}

export default locationSlice.reducer;
export const {updateLocationSettings, updateLocationData} = locationSlice.actions;
