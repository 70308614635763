import React, {useEffect, useRef} from 'react'
import {createPortal} from 'react-dom'

export default function Loading() {
    const dialog = useRef(null)
    useEffect(() => {
        if (dialog.current) {
            // @ts-ignore
            dialog.current.showModal()
        }
    }, [])

    return createPortal(
        <dialog ref={dialog}>{
            <div>
                Loading...
            </div>
        }</dialog>, document.body
    )
}
