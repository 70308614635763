import React from 'react';
import styles from "./StatView.module.css"

interface StatViewProps {
    title: string,
    text: string | number,
}

export function StatView(
    props: StatViewProps
) {
    return (
        <div className={styles.statView}>
            <div className={styles.statView__title}>{props.title}</div>
            <div className={styles.statView__text}>{props.text}</div>
        </div>
    )
}

export function HorizontalStatView(
    props: StatViewProps
) {
    return (
        <div className={styles.statView_horizontal}>
            <div className={styles.statView_horizontal__title}>{props.title}</div>
            <div className={styles.statView_horizontal__text}>{props.text}</div>
        </div>
    )
}
