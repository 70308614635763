import {createSlice} from '@reduxjs/toolkit'
import {RawUser} from '../../model/RawServerData'
import {TopStatsUpdateAction} from '../../model/types'
import {AppThunk} from '../store'
import {sendEvent} from '../../features/socket/socketSlice'
import {SocketRequestTypes} from '../../model/interfaces'

export interface TopStateUser {
    place:              number
    id:                 number
    firstName:          string
    username:           string
    value:              number
}

export interface TopState {
    balanceMe4ton:      TopStateUser[]
    balanceNe4to:       TopStateUser[]
    balanceE4:          TopStateUser[]

    incomeMe4ton:       TopStateUser[]
    incomeE4:           TopStateUser[]

    received:           boolean
}

const initialState: TopState = {
    balanceMe4ton:      [],
    balanceNe4to:       [],
    balanceE4:          [],

    incomeMe4ton:       [],
    incomeE4:           [],

    received:           false
}

function transformUser(data: RawUser) {
    return {
        id:         data.id,
        firstName:  data.first_name,
        username:   data.username,
    }
}

export const topStatsSlice = createSlice({
    name: 'topSlice',
    initialState,
    reducers: {
        updateTopStats: (state, action: TopStatsUpdateAction) => {
            const data = action.payload.topStats
            // console.log('top stats', data)
            state.balanceMe4ton = Object.keys(data.top_balance_me4ton).map(key => {
                const el = data.top_balance_me4ton[+key]
                return {
                    place:  +key,
                    value:  +el.balancem,
                    ...transformUser(el),
                }
            })

            state.balanceNe4to = Object.keys(data.top_balance_ne4to).map(key => {
                const el = data.top_balance_ne4to[+key]
                return {
                    place:  +key,
                    value:  +el.balance,
                    ...transformUser(el),
                }
            })

            state.balanceE4 = Object.keys(data.top_balance_e4).map(key => {
                const el = data.top_balance_e4[+key]
                return {
                    place:  +key,
                    value:  +el.balancee4,
                    ...transformUser(el),
                }
            })

            state.incomeMe4ton = Object.keys(data.top_dohod_me4ton).map(key => {
                const el = data.top_dohod_me4ton[+key]
                return {
                    place:  +key,
                    value:  +el.dohod,
                    ...transformUser(el),
                }
            })

            state.incomeE4 = Object.keys(data.top_dohod_e4).map(key => {
                const el = data.top_dohod_e4[+key]
                return {
                    place:  +key,
                    value:  +el.dohode4,
                    ...transformUser(el),
                }
            })


            state.received = true
        }
    }
});

export const RequestTopStats = (): AppThunk => (dispatch, getState) => {
    dispatch(sendEvent({
        event: SocketRequestTypes.GetTopStats,
        message: {
            qty: 10
        }
    }))
};

export default topStatsSlice.reducer;

export const {updateTopStats} = topStatsSlice.actions;
//
// /**
//  * Отпраыляет весь массив данных с сервера обратно, но с измененныеми полями
//  * @param socketEvent название события сокета
//  * @param newStats новые значения параметров либо лямбда, которая возвращает объект с новыми значениями, напр. { pwr: 0 }
//  * Поля могут быть только те, которые есть в RawServerData
//  */
// export const updateFarm = (
//     socketEvent: string,
//     newStats: ((data: RawServerData) => StatsUpdate) | StatsUpdate
// ): AppThunk => (dispatch, getState) => {
//     const update = typeof newStats === "function" ? newStats(getState().stats.rawData) : newStats
//     // console.log("i`m in statsSlice, update", update)
//     dispatch(sendEvent({
//         event: socketEvent,
//         message: {...getState().stats.rawData, ...update}
//     }))
// };
//
// function getDateBefore(days: number) {
//     const date = new Date(Date.now() - days * 24 * 60 * 60 * 1000)
//     return `${date.getDate()}.${(date.getMonth() + 1).toString().padStart(2, "0")}`
// }
//
// // TODO remove when whe get rid of "h1, h2, h3" etc
// function parseIncomeAndHashRate(data: RawServerData): ChartValue[][] {
//     const income = []
//     const hashRate = []
//
//     for (let i = 0; i < 7; i++) {
//         const date = getDateBefore(i)
//         income.push({
//             date: date,
//             value: +data[`d${i + 1}` as keyof RawServerData]
//         })
//         hashRate.push({
//             date: date,
//             value: +data[`h${i + 1}` as keyof RawServerData]
//         })
//     }
//     return [income.reverse(), hashRate.reverse()]
// }
