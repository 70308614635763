import styles from "./ShopDialog.module.css"
import React from "react"
import {LocationEquipment} from "../../../app/slices/locationSlice";
import {ShopItemView} from "./ShopItem";

interface DealerDialogProps {
    items: LocationEquipment[]
    onBuy: (item: string) => void
}

export function ShopDialog(props: DealerDialogProps) {
    return (
        <div className={styles.dealerDialog}>
            {
                props.items.map((item) => (
                    <ShopItemView key={item.id} item={item} onBuy={props.onBuy}></ShopItemView>
                    // <DealerView key={item.id} item={item} onSellItemToDealer={props.onSellItemToDealer}></DealerView>
                ))
            }
        </div>
    )
}
