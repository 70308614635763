import React, {useEffect, useState} from 'react'

import styles from './Miner.module.css';
import {Shop} from "../../shop/view/Shop";
import Navbar from "../../../components/navbar/Navbar";
import {FaCoins, FaGear} from "react-icons/fa6";
import {FaChartBar, FaTrophy, FaHome} from 'react-icons/fa'
import {Stats} from "../../stats/view/Stats";
import Rigs from "../../rigs/view/Rigs";
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import {initSocket} from "../../socket/socketSlice";
import Wallet from '../../wallet/view/Wallet'
import {useIsConnectionRestored, useTonAddress} from '@tonconnect/ui-react'
import Loading from '../../../components/loading/Loading'
import Top from '../../top/view/Top'
import Header from "../../../components/header/Header";
import Location from "../../location/view/Location";
import {updateTonData} from "../../../app/slices/tonSlice";
import {store} from "../../../app/store";

// TODO сделать нормальный роутинг
const TAB_SHOP =    0
const TAB_RIGS =    1
const TAB_LOCATION =    2
const TAB_TOP =     3
const TAB_STATS =   4

const TAB_WALLET =  10

function switchTab(tab: number) {
    switch (tab) {
        case TAB_SHOP:
            return <Shop/>
        case TAB_RIGS:
            return <Rigs/>
        case TAB_LOCATION:
            return <Location/>
        case TAB_TOP:
            return <Top/>
        case TAB_STATS:
            return <Stats/>

        case TAB_WALLET:
            return <Wallet/>
    }
}

export function MinerScreen() {
    const connectionRestored = useIsConnectionRestored();
    const tonAddress = useTonAddress();
    const statsReceived = useAppSelector((state) => state.stats.statsReceived)

    const dispatch = useAppDispatch()

    useEffect(() => {
        // TODO uncomment on prod
        if (connectionRestored) {
            // TODO uncomment on prod
            store.dispatch(updateTonData({address: tonAddress}))
            dispatch(initSocket())
            Telegram.WebApp.expand()
            Telegram.WebApp.disableVerticalSwipes()
        }
    }, [connectionRestored, dispatch])

    const [curTab, setTab] = useState(TAB_STATS)
    return (
        <div className={styles.miner}>
            {!statsReceived && <Loading/>}
            <Header activeWalletTab={curTab === TAB_WALLET} onClick={() => setTab(TAB_WALLET)}></Header>
            <div className={styles.miner__container}>
                {switchTab(curTab)}
            </div>
            <Navbar activeTab={curTab} onClick={(tab) => setTab(tab)}
                 items={[
                     {name: "Маркет", icon: <FaCoins size={20}/>},
                     {name: "Мои риги", icon: <FaGear size={20}/>},
                     {name: "Локация", icon: <FaHome size={20}/>},
                     {name: "Топ", icon: <FaTrophy size={20}/>},
                     {name: "Статистика", icon: <FaChartBar size={20}/>}
                 ]}
            />
        </div>
    )
}
