import React, {useState} from "react"
import styles from "./InOutAmountDialog.module.css"
import {styled, TextField} from "@mui/material";
import {Button} from "../../../components/view/Button";

interface LocationDialogProps {
    label:                  string
    buttonText:             string
    onButtonClick:          (amount: number) => void

    description?:           string
    inputPlaceholder?:      string
    maxAmount?:             number
    minAmount?:             number
}

const ColoredTextField = styled(TextField)({
        // '& label.Mui-focused': {
        //     color: '#A0AAB4',
        // },
        // '& .MuiInput-underline:after': {
        //     borderBottomColor: '#B2BAC2',
        // },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E0E3E7',

            },
            '&:hover fieldset': {
                borderColor: '#B2BAC2',

            },
            '&.Mui-focused fieldset': {
                borderColor: '#6F7E8C',
            },
            '& textarea': {
                color: '#E0E0E0',
            },
        },
    }
);

export function InOutAmountDialog(props: LocationDialogProps) {
    const [amount, setAmount] = useState<number>(0)

    function checkDisabled() {
        if (!Number.isInteger(amount) || amount <= 0) return true
        if (props.maxAmount && amount > props.maxAmount) return true
        if (props.minAmount && amount < props.minAmount) return true
        return false
    }

    function clicked() {
        if (checkDisabled()) return
        props.onButtonClick(amount)
    }

    return (
        <div>
            <div className={styles.row}>
                <div className={styles.inputContainer}>
                    <div className={styles.inputLabel}>{props.label}</div>
                    <ColoredTextField
                        id="standard-textarea"
                        // label="Введите сумму E4"
                        // type="number"
                        placeholder={props.inputPlaceholder ? props.inputPlaceholder : 'Сумма'}
                        multiline
                        variant="outlined"
                        focused
                        value={amount}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            let newValue = +event.target.value
                            if (!Number.isInteger(+newValue)) {
                                newValue = 0
                            }
                            setAmount(+newValue);
                        }}
                    />
                </div>
            </div>

            {
                props.description && <div className={styles.row}>
                    <div className={styles.description}>{props.description}</div>
                </div>
            }

            <div className={styles.buttonsContainer}>
                <Button text={props.buttonText}
                        disabled={checkDisabled()}
                        onClick={() => {
                            clicked()
                        }}/>
            </div>
        </div>
    )
}
