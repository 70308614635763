import {Vendor} from "../model/Vendor";
import styles from "./VendorView.module.css"
import classNames from "classnames";
import Price from "./Price";

interface VendorViewProps {
    vendor:         Vendor
    price:          number
    defectChance:   number
    vendorStock?:   number
    onClick:        (vendor: Vendor) => void
}

export function GetDefectDescription(defectChance: number): string {
    return `Шанс брака: ${defectChance}%`
}

export function VendorView(props: VendorViewProps) {
    let vendorStyle = ``

    switch (props.vendor) {
        case Vendor.shop:
            vendorStyle = styles.vendor_new
            break
        case Vendor.ali:
            vendorStyle = styles.vendor_ali
            break
        case Vendor.avito:
            vendorStyle = styles.vendor_avito
            break
        case Vendor.friend:
            vendorStyle = styles.vendor_friend
    }
    return (
        <div className={classNames(styles.vendor, vendorStyle)} onClick={() => props.onClick(props.vendor)}>
            <div className={styles.row}>
                <div>
                    <div className={styles.title}>
                        {props.vendor.title}
                    </div>
                    <div className={styles.description}>
                        {
                            props.defectChance > 0
                                ? <span className={styles.debuffText}>{GetDefectDescription(props.defectChance)}</span>
                            : 'В идеальном состоянии'
                        }
                    </div>
                </div>
                <div className={styles.price}>
                    <Price price={props.price | 0}/>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.count}>
                    {
                        props.vendorStock === 0
                            ? 'Нет в наличии'
                            : `В наличии ${props.vendorStock} шт`
                    }
                </div>
            </div>
            {/*<div className={styles.row}>*/}
            {/*    {*/}
            {/*        props.defectChance > 0*/}
            {/*            ? <div className={styles.debuff}>{GetDefectDescription(props.defectChance)}</div>*/}
            {/*            : <div className={classNames(styles.debuff, styles.noDebuff)}>В идеальном состоянии</div>*/}
            {/*    }*/}

            {/*</div>*/}

        </div>
    )
}
