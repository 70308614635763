import React from "react";
import styles from "./Button.module.css"
import classNames from 'classnames'

interface ButtonProps {
    text?: string,
    icon?: React.JSX.Element,
    selected?: boolean,
    disabled?: boolean,
    onClick: () => void
}

export function Button(props: ButtonProps) {
    return (
        <div className={classNames(
            styles.button,
            props.selected ? styles.button__selected : '',
            props.disabled ? styles.button__disabled : '',
        )}
             onClick={() => props.onClick()}>
            {props.icon && <div className={styles.button__image}>{props.icon}</div>}
            {props.text && <div className={styles.button__text}>{props.text}</div>}
        </div>
    )
}
