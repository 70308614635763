import {FaCoins} from "react-icons/fa6";
import styles from "./Price.module.css";

export default function Price(
    props: { price: number } & { className?: string }
) {
    return (
        <div className={props.className}>
            <FaCoins className={styles.price__icon} size={20}/>
            <div className={styles.price__value}>{props.price.toString()}</div>
        </div>
    )
}
