"use client";
import {io, Socket} from "socket.io-client"

export interface SocketInterface {
    socket:     Socket
}

export type SocketOpts = {
    uri: string
}

class SocketConnection implements SocketInterface {
    public socket: Socket;

    constructor(uri: string) {
        this.socket = io(uri);
    }
}

let socketConnection: SocketConnection | undefined;

class SocketFactory {
    public static create(opts: SocketOpts): SocketConnection {
        if (!socketConnection) {
            socketConnection = new SocketConnection(opts.uri);
        }
        return socketConnection;
    }
}

export default SocketFactory;
