import React from "react";
import styles from "./Rig.module.css"
import classNames from "classnames";
import {FaLock} from "react-icons/fa6";

export function EmptyRig() {
    return (
        <div className={classNames(styles.rig, styles.rig_empty)}>
            <FaLock/>
        </div>
    )
}
