import {Middleware} from "redux";
import {connectionEstablished, connectionLost, initSocket, sendEvent} from "./socketSlice"

import SocketFactory from "./../../service/SocketFactory"
import type {SocketInterface} from "../../service/SocketFactory"
import {AppDispatch} from "../../app/store"
import {SocketRequestTypes} from '../../model/interfaces'

enum SocketEvent {
    Connect = "connect",
    Disconnect = "disconnect",
    Error = "error"
}

export interface SocketEventHandler {
    eventType: string,
    handleEvent: (message: any, dispatch: AppDispatch) => void
}

function socketMiddleware(
    optionsProvider: () => {
        uri:        string,
        id:         string,
        token:      string,
        tonwallet?: string
    },
    handlers: SocketEventHandler[]
): Middleware {
    return (store) => {
        let socketInterface: SocketInterface;

        return (next) => (action) => {
            if (initSocket.match(action)) {
                if (!socketInterface && typeof window !== "undefined") {
                    socketInterface = SocketFactory.create({
                        uri: optionsProvider().uri
                    });

                    socketInterface.socket.on(SocketEvent.Connect, () => {
                        const tonAddress = store.getState().ton.address

                        socketInterface.socket.emit(SocketRequestTypes.GetData, {
                            id:         optionsProvider().id,
                            status:     optionsProvider().token,
                            // tonwallet:  optionsProvider().tonwallet,
                            //  TODO: uncomment in prod
                            tonwallet:  tonAddress ? tonAddress : optionsProvider().tonwallet,
                        })
                        store.dispatch(connectionEstablished());
                    });

                    socketInterface.socket.on(SocketEvent.Error, (message) => {
                        console.error('error in socket interface', message);
                    });

                    socketInterface.socket.on(SocketEvent.Disconnect, (reason) => {
                        store.dispatch(connectionLost());
                    });

                    handlers.forEach((handler) => {
                        socketInterface.socket.on(handler.eventType, (message) => {
                            handler.handleEvent(message, store.dispatch)
                        })
                    })
                }
            }

            if (sendEvent.match(action) && socketInterface) {
                // console.log('im in socket middleware', action.payload.event, action)
                const eventType = action.payload.event

                let message

                if (eventType === SocketRequestTypes.GetSettings) {
                    message = {
                        id: optionsProvider().id,
                        status: optionsProvider().token,
                        loc: action.payload.message.loc,
                    }
                // } else if (eventType === SocketRequestTypes.GetData) {
                //     const tonAddress = store.getState().ton.address
                //
                //     message = {
                //         id: optionsProvider().id,
                //         status: optionsProvider().token,
                //         tonwallet: optionsProvider().tonwallet,
                //         // tonwallet:  tonAddress ? tonAddress : optionsProvider().tonwallet,
                //     }
                } else {
                    const tonAddress = store.getState().ton.address
                    message = {
                        ...action.payload.message,

                        id: optionsProvider().id,
                        status: optionsProvider().token,
                        tonwallet:  tonAddress ? tonAddress : optionsProvider().tonwallet,
                    }
                }
                // console.log('im in socket middleware end', action.payload.event, message)

                socketInterface.socket.emit(action.payload.event, message);
            }

            next(action);
        };
    };
}

export default socketMiddleware;
