// Slice of store that manages Socket connections
import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";

export interface SocketState {
    isConnected: boolean;
    rooms: string[];
}

const initialState: SocketState = {
    isConnected: false,
    rooms: [],
};

type SendEventAction = PayloadAction<{
    message: any;
    event: string;
}>

// Now create the slice
const socketSlice = createSlice({
    name: "socket",
    initialState,
    // Reducers: Functions we can call on the store
    reducers: {
        initSocket: (_) => {
            return;
        },
        connectionEstablished: (state) => {
            state.isConnected = true;
        },
        connectionLost: (state) => {
            state.isConnected = false;
        },
        sendEvent: (_, action: SendEventAction) => {
            return;
        }
    },
});

export const {
    sendEvent,
    initSocket,
    connectionEstablished,
    connectionLost
} = socketSlice.actions;

export default socketSlice.reducer;
