import styles from "./Header.module.css";
import React from "react";
import classNames from "classnames";
import {FaWallet} from "react-icons/fa";
import {useAppSelector} from "../../app/hooks";
import {CurrencyObject} from '../../model/interfaces'

interface HeaderProps {
    activeWalletTab:    boolean
    onClick:            () => void
}

export default function Header(
    props: HeaderProps
) {
    const data = useAppSelector((state) => state.wallet)
    const classnames = classNames(
        styles.header__item,
        props.activeWalletTab ? styles.header__item__current : undefined
    )

    return (
        <div className={styles.header}>
            <div className={styles.coinsContainer}>
                {
                    Object.keys(data.balances).map(currency => {
                        const value = data.balances[currency as keyof CurrencyObject]!
                        return <div key={'coinContainer_' + currency} className={styles.coinContainer}>
                            <div className={styles.coin}>{currency}</div>
                            <div className={styles.balance}>{value}</div>
                        </div>
                    })
                }
            </div>
            <div className={styles.buttonContainer}>
                <div className={classnames} onClick={() => props.onClick()}>
                    <div className={styles.header__item__icon}><FaWallet size={30}/></div>
                </div>
            </div>
        </div>
    )
}
