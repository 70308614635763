import styles from "./Rig.module.css"
import classNames from "classnames";
import {FaToggleOff, FaToggleOn} from "react-icons/fa6";
import React from "react";

interface RigProps {
    enabled: boolean,
    name: string,
}

export function Rig(
    props: RigProps
) {

    const buttonStyle = classNames(
        styles.rig__indicator, props.enabled ? styles.rig__indicator_enabled : styles.rig__indicator_disabled
    )

    const rigStyle = classNames(
        styles.rig,
        props.enabled ? styles.rig_enabled : styles.rig_disabled,
    )

    return (
        <div className={rigStyle}>
            {props.enabled ?
                <FaToggleOff className={buttonStyle}/> : <FaToggleOn className={buttonStyle}/>
            }
            <div className={styles.rig__name}>{props.name}</div>
        </div>
    )
}
