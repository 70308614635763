import cpuIcon from "../../../res/img/shop/ic_cpu.svg"
import cpuMotherboard from "../../../res/img/shop/ic_motherboard.svg"
import cpuCase from "../../../res/img/shop/ic_gpu.svg"
import cpuPower from "../../../res/img/shop/ic_power.svg"
import {Price} from "./Price";
import {Vendors} from '../../../model/interfaces'

export type ShopItem = {
    id:             'mb' | 'bp' | 'cpu' | 'gpu'
    name:           string
    description:    string
    icon:           string
    price:          Price
    vendorsStock?:  Vendors

    buyBackPrice?:  number
}

// TODO remove later
// договорились, что в дальнейшем будет все итемы будут приходить с сервера с описанием, ценой и остатком у вендора
export const CPU: ShopItem = {
    price: {avito: 0, ali: 0, friend: 0, shop: 0},
    id: "cpu",
    name: "Процессор",
    description: "Этот вид техники отвечает за вычислительные операции",
    icon: cpuIcon
}

export const Motherboard: ShopItem = {
    price: {avito: 0, ali: 0, friend: 0, shop: 0},
    id: "mb",
    name: "Материнская плата",
    description: "Плата, на которой размещаются компоненты для функционирования устройства",
    icon: cpuMotherboard,
}

export const GPU: ShopItem = {
    price: {avito: 0, ali: 0, friend: 0, shop: 0},
    id: "gpu",
    name: "Видеокарта",
    description: "Устройство, преобразующее графический образ, в форму, пригодную для дальнейшего вывода на экран монитора",
    icon: cpuCase,
}

export const PowerUnit: ShopItem = {
    price: {avito: 0, ali: 0, friend: 0, shop: 0},
    id: "bp",
    name: "Блок питания",
    description: "Устройство для преобразования напряжения переменного тока в напряжение постоянного тока.",
    icon: cpuPower,
}
export const ShopItems = [Motherboard, GPU, CPU, PowerUnit] // todo remove later
