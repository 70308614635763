import styles from "./ShopItem.module.css"
import React from 'react'
import {LocationEquipment} from '../../../app/slices/locationSlice'
import {Button} from "../../../components/view/Button"
import {CurrencyObject} from '../../../model/interfaces'

interface ShopItemProps {
    item:   LocationEquipment
    onBuy: (item: string) => void
}

interface Price {
    currency:   string
    value:      number
}

export function ShopItemView(props: ShopItemProps) {
    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.rowSection}>
                    <div className={styles.titleInfoContainer}>
                        <div className={styles.title}>{props.item.name}</div>
                    </div>
                    <div className={styles.descriptionSection}>{props.item.description.info}</div>
                    {
                        Object.keys(props.item.consumption).map((currency: string) => {
                            const value = props.item.consumption[currency as keyof CurrencyObject]

                            if (value && value > 0) {
                                return <div key={'coinContainer_' + currency} className={styles.descriptionSection}>Потребление: {value} {currency} в час</div>
                            }
                        })
                    }
                </div>
                <div className={styles.rowSection}>
                    <div className={styles.priceSection}>
                        {
                            Object.keys(props.item.price).map((currency: string) => {
                                const value = props.item.price[currency as keyof CurrencyObject]

                                if (value && value > 0) {
                                    return <span key={'price_' + currency}
                                                 className={styles.itemPrice}>{value} {currency}</span>
                                }
                            })
                        }
                    </div>
                    <div className={styles.buttonSection}>
                        <Button text={"Купить"} onClick={() => props.onBuy(props.item.id)}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
