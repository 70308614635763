import {MinerScreen} from './features/miner/view/MinerScreen'
import {TonConnectUIProvider} from '@tonconnect/ui-react'

function GetScrollableEl(el: HTMLElement): HTMLElement | null {
    if (el.classList.contains('scrollableContainer')) return el

    return el.parentElement ? GetScrollableEl(el.parentElement) : null
}

function App() {
    const tonManifest = 'https://e4pool.com/assets/ton/e4pool.manifest.json'

    // const overflow = 100
    const overflow = 0
    document.body.style.overflowY = 'hidden'
    document.body.style.marginTop = `${overflow}px`
    document.body.style.height = window.innerHeight + overflow + 'px'
    document.body.style.paddingBottom = `${overflow}px`
    window.scrollTo(0, overflow)

    const footerHeight = 75
    let ts = 0

    const onTouchMove = (e: TouchEvent) => {
        const el = e.srcElement as HTMLElement
        if (!el) {
            e.preventDefault()
            return
        }
        const scrollableEl = GetScrollableEl(el)

        if (scrollableEl) {
            if (document.documentElement.scrollHeight <= window.innerHeight) {
                if ((scrollableEl.offsetTop + scrollableEl.scrollHeight) <= (window.innerHeight - footerHeight)) {
                    e.preventDefault()
                }
            }
            const offsetHeight = scrollableEl.offsetHeight
            // const scroll = scrollableEl.scrollTop   //  always 0
            const te = e.changedTouches[0].clientY
            const scrollDirection = ts < te ? 'down' : 'up'
            if (scrollDirection === 'down' && (ts - te) > offsetHeight) {
                e.preventDefault()
            }
        } else {
            e.preventDefault()
        }
    }
    document.documentElement.addEventListener('touchmove', onTouchMove, {passive: false})
    return (
        <TonConnectUIProvider manifestUrl={tonManifest}>
            <MinerScreen/>
        </TonConnectUIProvider>)
}

export default App
