import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RawServerData} from "../../model/RawServerData"
import {AppThunk} from "../../app/store"
import {sendEvent} from "../socket/socketSlice"

export type ChartValue = {
    date: string, value: number
}

/**
 * Содержит любые поля из RawServerData
 */
export type StatsUpdate = {
    [K in keyof RawServerData]?: any
}

export interface StatsState {
    activeRigs:         number
    totalRigs:          number
    gpu:                number
    hashrate:           number
    dust:               number
    temp:               number
    noise:              number
    intensity:          number
    income:             number
    incomeE4:           number
    consumption:        number
    hashrateChart:      ChartValue[]
    incomeChart:        ChartValue[]
    rawData:            any

    statsReceived:      boolean
}

const initialState: StatsState = {
    activeRigs:         0,
    totalRigs:          0,
    gpu:                0,
    hashrate:           0,
    dust:               0,
    temp:               0,
    noise:              0,
    intensity:          0,
    income:             0,
    incomeE4:           0,
    consumption:        0,
    hashrateChart:      [{date: "", value: 0}],
    incomeChart:        [{date: "", value: 0}],
    rawData:            {},
    statsReceived:      false
};

type StatsUpdateAction = PayloadAction<{
    data: RawServerData
}>

export const statsSlice = createSlice({
    name: 'statsSlice',
    initialState,
    reducers: {
        updateStats: (state, action: StatsUpdateAction) => {
            const newStats = action.payload.data
            const [incomeChart, hashrateChart] = parseIncomeAndHashRate(newStats)
            state.intensity = +newStats.intensity
            state.dust = +newStats.pill
            state.temp = +newStats.temp + +newStats.temppill
            state.gpu = +newStats.gpu
            state.hashrate = +newStats.hashrate
            state.totalRigs = +newStats.rigqty
            state.income = +newStats.dohod
            state.incomeE4 = +newStats.dohode4
            state.consumption = +newStats.rashod
            state.incomeChart = incomeChart
            state.hashrateChart = hashrateChart

            state.statsReceived = true
            state.rawData = newStats
        }
    }
});
export default statsSlice.reducer;

export const {updateStats} = statsSlice.actions;

/**
 * Отпраыляет весь массив данных с сервера обратно, но с измененныеми полями
 * @param socketEvent название события сокета
 * @param newStats новые значения параметров либо лямбда, которая возвращает объект с новыми значениями, напр. { pwr: 0 }
 * Поля могут быть только те, которые есть в RawServerData
 */
export const updateFarm = (
    socketEvent: string,
    newStats: ((data: RawServerData) => StatsUpdate) | StatsUpdate
): AppThunk => (dispatch, getState) => {
    const update = typeof newStats === "function" ? newStats(getState().stats.rawData) : newStats
    // console.log("i`m in statsSlice, update", update)
    dispatch(sendEvent({
        event: socketEvent,
        message: {...getState().stats.rawData, ...update}
    }))
};

function getDateBefore(days: number) {
    const date = new Date(Date.now() - days * 24 * 60 * 60 * 1000)
    return `${date.getDate()}.${(date.getMonth() + 1).toString().padStart(2, "0")}`
}

// TODO remove when whe get rid of "h1, h2, h3" etc
function parseIncomeAndHashRate(data: RawServerData): ChartValue[][] {
    const income = []
    const hashRate = []

    for (let i = 0; i < 7; i++) {
        const date = getDateBefore(i)
        income.push({
            date: date,
            value: +data[`d${i + 1}` as keyof RawServerData]
        })
        hashRate.push({
            date: date,
            value: +data[`h${i + 1}` as keyof RawServerData]
        })
    }
    return [income.reverse(), hashRate.reverse()]
}
