import React, {useMemo} from "react";
import {AxisOptions, Chart} from "react-charts";
import styles from "./Charts.module.css"

export type DailyValues = {
    date: string,
    value: number,
}

export function DateChart(
    props: {
        label: string,
        hideXAxisLabels: boolean
        data: DailyValues[]
    }
) {
    const primaryAxis = useMemo(
        (): AxisOptions<DailyValues> => ({
            getValue: datum => datum.date,
            // formatters: {
            //     scale: () => '',
            // }
        }),
        []
    )
    if (props.hideXAxisLabels) {
        primaryAxis.formatters = {
            scale: () => ''
        }
    } else {
        delete primaryAxis.formatters
    }

    const values = useMemo(
        (): AxisOptions<DailyValues>[] => [
            {
                min: 0,
                getValue: datum => datum.value,
                elementType: 'bar'
            },
        ],
        []
    )

    const data = useMemo(() => ([{...props}]), [props])

    return (
        <Chart
            className={styles.chart}
            options={{
                data: data,
                primaryAxis: primaryAxis,
                secondaryAxes: values,
                padding: {
                    left: 16,
                    right: 16,
                    bottom: 16,
                    top: 16
                }
            }}
        />
    )
}
