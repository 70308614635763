import styles from "./DealerDialog.module.css"
import React from "react"
import {DealerView} from './DealerView'
import {DealerItem} from '../model/DealerItem'

interface DealerDialogProps {
    items: DealerItem[]
    onSellItemToDealer: (item: string, count: number) => void
}

export function DealerDialog(props: DealerDialogProps) {
    return (
        <div className={styles.dealerDialog}>
            {
                props.items.map((item) => (
                    <DealerView key={item.id} item={item} onSellItemToDealer={props.onSellItemToDealer}></DealerView>
                ))
            }
        </div>
    )
}
