import styles from "./VendorDialog.module.css"
import React from "react";
import {VendorView} from "./VendorView";
import {Vendor} from "../model/Vendor";
import {Price} from "../model/Price";
import {Vendors} from '../../../model/interfaces'

interface VendorDialogProps {
    price:          Price
    defectChances:  any
    vendorsStock?:   Vendors
    onVendorClick:  (vendor: Vendor) => void
}

export function VendorDialog(props: VendorDialogProps) {
    return (
        <div className={styles.vendorDialog}>
            {
                Object.entries(Vendor).map(([name, vendor]) => (
                    <VendorView key={name}
                                vendor={vendor}
                                price={props.price[name as keyof Price]}
                                defectChance={props.defectChances[vendor.type]}
                                vendorStock={props.vendorsStock && props.vendorsStock[vendor.type]}
                                onClick={props.onVendorClick}/>
                ))
            }
        </div>
    )
}
