import styles from "./ShopItemView.module.css"
import Price from "./Price";
import {ToTitleCase} from '../../../app/functions'

interface ShopItemProps {
    name:       string
    price:      any
    icon:       string

    count:      number
    onClick:    () => void
}

export function ShopItemView(props: ShopItemProps) {
    const price = typeof props.price === 'number' ? props.price : props.price.shop

    return (
        <div className={styles.shopItem} onClick={() => props.onClick()}>
            <img src={props.icon} className={styles.shopItem__img} alt={""}/>

            <div className={styles.shopItem__info}>
                <div className={styles.shopItem__title}>{ToTitleCase(props.name)}</div>
                <br/>
                <div className={styles.shopItem__description}>В наличии: {props.count}</div>
            </div>

            <div className={styles.shopItem__price}>
                <Price price={price}/>
                <div className={styles.shopItem__priceCaption}>Цена в магазине</div>
            </div>
        </div>
    )
}
