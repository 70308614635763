import styles from "./Rigs.module.css"
import React from "react";
import {FaFan} from "react-icons/fa";
import {FaGaugeSimple, FaGaugeSimpleHigh, FaToggleOff, FaToggleOn} from "react-icons/fa6";
import {Button} from "../../../components/view/Button";
import {Rig} from "./Rig";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {EmptyRig} from "./EmptyRig";
import {Counter} from "../../../components/view/Counter";
import {blowRigs, overclockRigs, toggleAllRigs, toggleRigs} from '../rigsSlice'

export default function Rigs() {
    const dispatch = useAppDispatch()
    const activeRigs = useAppSelector((state) => state.rigs.activeRigs)
    const totalRigs = useAppSelector((state) => state.rigs.totalRigs)
    const availableRigs = useAppSelector((state) => state.settings.rigsLimit)
    const intensity = useAppSelector((state) => state.stats.intensity)

    return (
        <div className={styles.rigs}>
            <div className={styles.rigs__list}>
                {
                    Array(availableRigs).fill(0).map(
                        (_, i) => (
                            i < totalRigs
                                ? (<Rig key={`rig${i}`} enabled={i < activeRigs} name={`R${i + 1}`}/>)
                                : (<EmptyRig key={`rig${i}`}/>)
                        )
                    )
                }
            </div>


            <div className={styles.rigsSettings__container}>
                {/*/!*<div className={styles.rigSettings__hint}></div>*!/  what is it?*/}
                <div className={styles.rigSettings__overclock}>
                    <div className={styles.rigSettings__overclock__description}>
                        Разгон
                    </div>
                    <div className={styles.rigSettings__overclock__optionsContainer}>
                        <div className={styles.rigSettings__overclock__option}>
                            <Button text={'75%'} selected={intensity === 75}
                                    icon={<FaGaugeSimpleHigh style={{transform: 'rotateY(180deg)'}}/>}
                                    onClick={() => dispatch(overclockRigs(75))}/>
                        </div>
                        <div className={styles.rigSettings__overclock__option}>
                            <Button text={'100%'} selected={intensity === 100}
                                    icon={<FaGaugeSimple/>}
                                    onClick={() => dispatch(overclockRigs(100))}/>
                        </div>
                        <div className={styles.rigSettings__overclock__option}>
                            <Button text={'125%'} selected={intensity === 125}
                                    icon={<FaGaugeSimpleHigh/>}
                                    onClick={() => dispatch(overclockRigs(125))}/>
                        </div>
                    </div>
                </div>

                <div className={styles.rigSettings__hint}></div>
                <div className={styles.rigSettings__buttons}>
                    <Button text={"Включить все"}
                            icon={<FaToggleOn/>}
                            onClick={() => dispatch(toggleAllRigs(true))}/>
                    <Button text={"Выключить все"}
                            icon={<FaToggleOff/>}
                            onClick={() => dispatch(toggleAllRigs(false))}/>

                    <Button text={"Продуть"}
                            icon={<FaFan/>}
                            onClick={() => dispatch(blowRigs())}/>

                    <div>
                        <Counter value={activeRigs}
                                 maxValue={totalRigs}
                                 onPlus={() => dispatch(toggleRigs(1))}
                                 onMinus={() => dispatch(toggleRigs(-1))}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
