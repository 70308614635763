import {createSlice} from '@reduxjs/toolkit'
import {DataUpdateAction} from '../../model/types'
// TODO move it
import { Buffer } from "buffer"
import {AppThunk} from '../store'
import {sendEvent} from '../../features/socket/socketSlice'
import {CurrencyObject, SocketRequestTypes} from '../../model/interfaces'
window.Buffer = Buffer

export interface WalletState {
    balances:               CurrencyObject
    fees:                   CurrencyObject

    depositAddress:         string
    depositJettonMaster:    string
    incomingId:             string

    withdrawalLimit:        number

    NFTs:               NFT[]
}
export interface NFT {
    name:           string
    image:          string
    income:         number
    compatibility:  number

    // attributes:     any
}

const initialState: WalletState = {
    balances: {
        E4:                 0,
        ne4to:              0,
        me4ton:             0,
    },
    fees: {
        E4:                 0,
        ne4to:              0,
        me4ton:             0,
    },
    NFTs:                   [],
    depositAddress:         '',
    depositJettonMaster:    '',
    incomingId:             '',
    withdrawalLimit:        5000
}

export const walletSlice = createSlice({
    name: 'walletSlice',
    initialState,
    reducers: {
        updateWalletData: (state, action: DataUpdateAction) => {
            const data = action.payload.data

            state.balances = {
                E4:                 +data.wallets?.balances?.e4,
                ne4to:              +data.wallets?.balances?.ne4to,
                me4ton:             +data.wallets?.balances?.me4ton,
            }
            state.fees = {
                E4:                 +data.wallets?.fees?.e4,
                ne4to:              +data.wallets?.fees?.ne4to,
                me4ton:             +data.wallets?.fees?.me4ton,
            }

            //  TODO: remove hardcode
            state.depositAddress =      'UQBfCxVJ1M00XS3Wi0nabGCtdE_IxaaPCjLFOBLp_VuYsVH0'
            state.depositJettonMaster = 'EQDNlNA2NSbWtigW37WX__FIu4Y2NNPwijJd3Ktmhi7gdCyv'
            state.incomingId =          data.wallets?.incoming_id

            if (data.wallets?.withdrawalLimit) {
                state.withdrawalLimit = data.wallets.withdrawalLimit
            }

            state.NFTs = !data.nfts ? [] : data.nfts.map(nft => {
                return {
                    name:           nft.name,
                    image:          nft.img,
                    income:         nft.dohod,
                    compatibility:  nft.compatibility,
                    // attributes:     nft.attributes
                }
            })
        }
    }
})

export const WithdrawRequest = (currency: string, amount: number): AppThunk => async (dispatch) => {
    const eventName = `withdraw`;

    dispatch(sendEvent({
        event: SocketRequestTypes.Withdraw,
        message: {
            currency,
            amount
        }
    }))
}

export default walletSlice.reducer
export const {updateWalletData} = walletSlice.actions
