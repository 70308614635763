import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RawServerSettings} from "../../model/RawServerData"
import {AppThunk} from "../../app/store"
import {sendEvent} from "../socket/socketSlice"
import {SocketRequestTypes} from '../../model/interfaces'

export interface SettingsState {
    rigsLimit:          number
    gpuLimit:           number
    location:           string

    settingsReceived:   boolean
}

const initialState: SettingsState = {
    gpuLimit:           0,
    rigsLimit:          0,
    location:           '',
    settingsReceived:   false
};

type SettingsUpdateAction = PayloadAction<{
    settings: RawServerSettings;
}>

export const settingsSlice = createSlice({
    name: 'settingsSlice',
    initialState,
    reducers: {
        updateSettings: (state, action: SettingsUpdateAction) => {
            const data = action.payload.settings
            // console.log('settings', data)
            state.gpuLimit = +data.limgpu
            state.rigsLimit = +data.limrig
            state.location = data.title

            state.settingsReceived = true
        },
    }
});

let previousLocation: number = 0

export const updateSettingsIfNeeded = (): AppThunk => (dispatch, getState) => {
    if (previousLocation === 0) {
        previousLocation = getState().location.locationId
        // console.log('updateSettingsIfNeeded', 'case A')
    } else if (previousLocation !== getState().location.locationId) {
        // console.log('updateSettingsIfNeeded', 'case B')
    } else if (!getState().settings.settingsReceived) {
        // console.log('updateSettingsIfNeeded', 'case C')
    } else {
        return
    }

    dispatch(RequestSettings())
};

export const RequestSettings = (): AppThunk => (dispatch, getState) => {
    dispatch(sendEvent({
        event: SocketRequestTypes.GetSettings,
        message: {
            loc: getState().location.locationId,
        }
    }))
};


export default settingsSlice.reducer;

export const {updateSettings} = settingsSlice.actions;
