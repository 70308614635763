import styles from "./Wallet.module.css"
import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import {HorizontalStatView} from '../../stats/view/StatView'
import {
    TonConnectButton,
    useTonAddress,
    useTonConnectUI
} from '@tonconnect/ui-react'
import {NFTView} from './NFTView'
import {Button} from "../../../components/view/Button";
import {FaDonate} from "react-icons/fa";
import {Dialog} from "../../../components/view/Dialog";
import {getHttpEndpoint} from '@orbs-network/ton-access'
import {Address, beginCell, JettonMaster, toNano, TonClient} from '@ton/ton'
import {FaHandHoldingDollar} from 'react-icons/fa6'
import {InOutAmountDialog} from './InOutAmountDialog'
import {WithdrawRequest} from '../../../app/slices/walletSlice'
import {CurrencyObject} from '../../../model/interfaces'
import classNames from "classnames";

export default function Wallet() {
    const [tonConnectUI] = useTonConnectUI();
    const userAddress = useTonAddress();
    const tonAddress = useTonAddress();
    const dispatch = useAppDispatch()

    const data = useAppSelector((state) => state.wallet)
    const [depositDialogActive, setDepositDialogActive] = useState<boolean>(false)
    const [withdrawDialogActive, setWithdrawDialogActive] = useState<boolean>(false)

    async function SendCoins(address: string, amount: number, comment: string) {
        // console.log('SendCoins', address, amount, comment, data.incomingId.toString())

        const endpoint = await getHttpEndpoint()
        const client = new TonClient({ endpoint })

        const jettonMasterAddress = Address.parse(data.depositJettonMaster)
        const userAddress = Address.parse(address)

        const jettonMaster = client.open(JettonMaster.create(jettonMasterAddress))
        const jettonAddress = await jettonMaster.getWalletAddress(userAddress)

        // console.log('userAddress  ', address)
        // console.log('jettonAddress', jettonAddress.toString())
        const destinationAddress = Address.parse(data.depositAddress)
        const forwardPayload = beginCell()
            .storeUint(0, 32) // 0 opcode means we have a comment
            .storeStringTail(data.incomingId.toString())
            .endCell();

        // console.log('destinationAddress', destinationAddress)
        // console.log('jettonAddress', jettonAddress)
        const body = beginCell()
            .storeUint(0xf8a7ea5, 32)                 // jetton transfer op code
            .storeUint(0, 64)                         // query_id:uint64
            .storeCoins(toNano(amount))               // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - USDT, 9 - default). Function toNano use decimals = 9 (remember it)
            .storeAddress(destinationAddress)         // destination:MsgAddress
            .storeAddress(userAddress)                // response_destination:MsgAddress
            .storeUint(0, 1)                          // custom_payload:(Maybe ^Cell)
            .storeCoins(toNano("0.00000001"))         // forward_ton_amount:(VarUInteger 16) - if >0, will send notification message
            .storeUint(1,1)                           // forward_payload:(Either Cell ^Cell)
            .storeRef(forwardPayload)
            .endCell();

        const myTransaction = {
            validUntil: Math.floor(Date.now() / 1000) + 360,
            messages: [
                {
                    address: jettonAddress.toString(), // sender jetton wallet
                    amount: toNano("0.05").toString(), // for commission fees, excess will be returned
                    payload: body.toBoc().toString("base64") // payload with jetton transfer body
                }
            ]
        }
        try {
            const txProcess = tonConnectUI.sendTransaction(myTransaction)
            setDepositDialogActive(false)
            const res = await txProcess
            // console.log('res', res)
        }
        catch (e) {
            console.log('e', e)
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <TonConnectButton className={'pinned-right'}/>
            </div>

            {
                Object.keys(data.balances).map(currency => {
                    const value = data.balances[currency as keyof CurrencyObject]!
                    return <div key={'balance_' + currency} className={styles.row}>
                        <HorizontalStatView title={`Баланс ${currency}`} text={value}/>
                    </div>

                })
            }
            <div className={classNames(styles.row, 'scrollableContainer')}>
                <div className={styles.nftContainer}>
                    {
                        data.NFTs.map(
                            (item, i) => (
                                <NFTView nft={item} key={`nft${i}`}
                                />
                            )
                        )
                    }
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.buttonsContainer}>
                    {tonAddress && data.incomingId && data.depositAddress &&
                        <Button text={"Депозит E4"}
                                disabled={depositDialogActive}
                                icon={<FaDonate/>}
                                onClick={() => {
                                    tonAddress && setDepositDialogActive(true)
                                }}
                        />
                    }
                    {tonAddress &&
                            <Button text={"Вывод E4"}
                                    disabled={depositDialogActive}
                                    icon={<FaHandHoldingDollar/>}
                                    onClick={() => {
                                        tonAddress && setWithdrawDialogActive(true)
                                    }}
                            />
                    }
                </div>
            </div>

            {   /*   open dialog after 'deposit' button click    */
                depositDialogActive && (
                    <Dialog open={true} onClose={() => {
                        setDepositDialogActive(false)
                    }}>
                        <InOutAmountDialog label={"Пополнение E4"} buttonText={"Пополнить"}
                                           onButtonClick={(amount) => {
                                               SendCoins(userAddress, amount, data.incomingId)
                                           }}
                        />
                    </Dialog>
                )
            }

            {   /*   open dialog after 'withdraw' button click    */
                // || data.balanceE4 < data.withdrawalLimit
                withdrawDialogActive && (
                    <Dialog open={true} onClose={() => {
                        setWithdrawDialogActive(false)
                    }}>
                        <InOutAmountDialog label={"Вывод E4"} buttonText={"Вывести"} maxAmount={data.balances.E4} minAmount={data.withdrawalLimit}
                                           description={`Мин. сумма для вывода ${data.withdrawalLimit}. Комиссия ~${data.fees.E4} E4 удерживается с пользователя`}
                                           onButtonClick={(amount) => {
                            dispatch(WithdrawRequest('e4', amount))
                            setWithdrawDialogActive(false)
                        }}
                        />
                    </Dialog>
                )
            }
        </div>
    )
}
