import styles from "./Dialog.module.css";
import React from "react";
import {FaX} from "react-icons/fa6";

interface DialogProps {
    open: boolean,
    onClose: () => void
}

export function Dialog(
    props: React.PropsWithChildren<DialogProps>
) {
    return (
        <div className={props.open ? styles.dialog__wrapper : styles.dialog_hidden}>
            <div className={styles.dialog}>
                <div className={styles.closeButtonContainer}>
                    <div className={'pinned-right'}>
                        <FaX onClick={props.onClose} className={styles.closeButton} />
                    </div>
                </div>
                <div className={styles.dialog__content}>{props.children}</div>
            </div>
        </div>
    )
}
