import {createSlice} from '@reduxjs/toolkit'
import {TonUpdateAction} from '../../model/types'

export interface TonState {
    address:    string
}

const initialState: TonState = {
    address:    '',
}

export const tonSlice = createSlice({
    name: 'tonSlice',
    initialState,
    reducers: {
        updateTonData: (state, action: TonUpdateAction) => {
            const data = action.payload
            // console.log('updateTonSlice', data)
            state.address = data.address
        }
    }
})

export default tonSlice.reducer;
export const {updateTonData} = tonSlice.actions;
