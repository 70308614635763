import React from "react";
import styles from "./Counter.module.css"
import {FaMinus, FaPlus} from "react-icons/fa6";

interface CounterProps {
    value: number,
    maxValue: number,
    onPlus: () => void,
    onMinus: () => void
}

export function Counter(props: CounterProps) {
    return (
        <div className={styles.counter}>
            <div className={styles.counterValue}>
                <span className={styles.counterValue__current}>{props.value}</span>
                <span className={styles.counterValue__maxValue}>&nbsp;/&nbsp;{props.maxValue}</span>
            </div>
            <div className={styles.counterButton} onClick={() => props.onPlus()}><FaPlus/></div>
            <div className={styles.counterButton} onClick={() => props.onMinus()}><FaMinus/></div>
        </div>
    )
}