import styles from "./Shop.module.css"
import React, {useState} from "react";
import {VendorDialog} from "./VendorDialog";
import {ShopItemView} from "./ShopItemView";
import {Dialog} from "../../../components/view/Dialog";
import {ShopItem} from "../model/ShopItem";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {buyItem, sellItem, BuyWallet} from '../shopSlice'
import {UpgradeLocation} from '../../../app/slices/locationSlice'
import "../model/Vendor";
import {Button} from '../../../components/view/Button'
import {FaShoppingBag, FaHotel} from 'react-icons/fa'
import {DealerDialog} from './DealerDialog'
import {DealerItem} from '../model/DealerItem'
import {LocationDialog} from './LocationDialog'
import classNames from 'classnames'

export function Shop() {
    const [currentItem, setCurrentItem, ] = useState<ShopItem | null>(null)
    const [dealerDialogActive, setDealerDialogActive] = useState<boolean | null>(false)
    const [locationDialogActive, setLocationDialogActive] = useState<boolean | null>(false)

    const dispatch = useAppDispatch()
    const data = useAppSelector((state) => state.shop)
    const location = useAppSelector(state => state.location)
    const stock = useAppSelector((state) => state.rigs.stock)

    const dealerItems: DealerItem[] = data.availableItems.map(item => {
        return {
            id: item.id,
            name: item.name,
            icon: item.icon,
            description: item.description,
            price: item.buyBackPrice!,
            count: stock[item.id],
        }
    })

    return (
        <div className={classNames(styles.shop, 'scrollable')}>
            {
                data.availableItems.map(
                    (item, i) => (
                        <ShopItemView key={`shopItem${i}`}
                                      name={item.name}
                                      price={item.price}
                                      icon={item.icon}
                                      count={stock[item.id]}
                                      onClick={() => setCurrentItem(item)}
                        />
                    )
                )
            }

            <ShopItemView key={`shopItemWallet`}
                          name={data.wallet.name}
                          price={data.wallet.price}
                          icon={data.wallet.icon}
                          count={stock.wallet}
                          onClick={() => dispatch(BuyWallet())}
            />

            <div className={styles.shopButtons}>
                <Button text={"Скупщик"}
                        icon={<FaShoppingBag/>}
                        onClick={() => {setDealerDialogActive(true)}}/>

                <Button text={"Переезд"}
                        icon={<FaHotel/>}
                        onClick={() => {setLocationDialogActive(true)}}/>
            </div>


            {   /*  open shop variety dialog if clicked on some item (except wallet)    */
                currentItem && (
                <Dialog open={!!currentItem} onClose={() => setCurrentItem(null)}>
                    <VendorDialog price={currentItem.price}
                                  defectChances={data.defectChance}
                                  vendorsStock={currentItem.vendorsStock}
                                  onVendorClick={(vendor) => {
                                      dispatch(buyItem(currentItem, vendor))
                                      setCurrentItem(null)
                                  }}
                    />
                </Dialog>
            )}

            {   /*   open dialog after 'dealer' button click    */
                dealerDialogActive && (
                <Dialog open={true} onClose={() => {setDealerDialogActive(false)}}>
                    <DealerDialog items={dealerItems} onSellItemToDealer={(item: string, count: number) => {
                        dispatch(sellItem(item, count))
                        setDealerDialogActive(false)
                    }}/>
                </Dialog>
            )}

            {   /*   open dialog after 'location' button click    */
                locationDialogActive && (
                <Dialog open={true} onClose={() => {setLocationDialogActive(false)}}>
                    <LocationDialog location={location}
                                    onBuyNewLocation={() => {
                                        dispatch(UpgradeLocation(location.locationId))
                                        setLocationDialogActive(false)
                                    }}
                    />
                </Dialog>
            )}
        </div>
    )
}
