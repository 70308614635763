import styles from "./DealerView.module.css"
import Price from "./Price";
import {DealerItem} from '../model/DealerItem'
import Slider from '@mui/material/Slider';
import React from 'react'
import {Button} from '../../../components/view/Button'

interface ShopItemProps {
    item:   DealerItem

    onSellItemToDealer: (item: string, count: number) => void
}

export function DealerView(props: ShopItemProps) {
    const [sellCount, setSellCount] = React.useState<number>(0);
    const handleChange = (event: Event, newValue: number | number[]) => {
        setSellCount(newValue as number);
    };

    return (
        <div className={styles.dealerItemContainer}>
            <div className={styles.dealerItemRow}>
                <div className={styles.titleInfoContainer}>
                    <div className={styles.title}>{props.item.name}</div>
                    <div className={styles.count}>В наличии: {props.item.count}</div>
                </div>

                <div className={styles.dealerItemPrice}>
                    <Price price={props.item.price}/>
                </div>
            </div>
            <div className={styles.dealerItemRow}>
                <div className={styles.dealerItemSlider}>
                    <Slider
                        aria-label="Количество"
                        defaultValue={0}
                        valueLabelDisplay="auto"
                        shiftStep={1}
                        step={1}
                        marks
                        min={0}
                        max={props.item.count}
                        value={sellCount} onChange={handleChange}
                        disabled={props.item.count === 0}
                    />
                </div>

            </div>

            <div className={styles.dealerItemRow}>
                <div className={styles.totalPrice}>
                    Итого {sellCount * props.item.price}
                </div>
                <div className={'pinned-right'}>
                    <Button text={"Продать"}
                            disabled={props.item.count === 0}
                            onClick={() => {
                                if (sellCount === 0) return
                                props.onSellItemToDealer(props.item.id, sellCount)
                            }}/>
                </div>
            </div>

        </div>
    )
}
