import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from "../../app/store";
import {updateFarm} from "../stats/statsSlice";
import {Vendor} from "./model/Vendor";
import {ShopItem, ShopItems} from "./model/ShopItem";
import {RawServerData, RawServerSettings} from "../../model/RawServerData";
import {ToTitleCase} from '../../app/functions'

import walletIcon from "../../res/img/shop/wallet.svg"
import {Vendors} from '../../model/interfaces'

export interface ShopState {
    availableItems: ShopItem[],
    wallet: {
        name:           string
        description:    string
        icon:           string
        price:          number
    }
    defectChance:       Vendors
    vendorsStock:       {
        mb:             Vendors
        bp:             Vendors
        cpu:            Vendors
        gpu:            Vendors
    }
}
const nullVendor = {
    shop:                   0,
    tradePlatform:          0,
    tradePlatformChina:     0,
    used:                   0
}
const initialState: ShopState = {
    availableItems: [],
    wallet: {
        name:           '',
        description:    '',
        icon:           '',
        price:          0
    },
    defectChance:       Object.assign({}, nullVendor),
    vendorsStock: {
        mb:             Object.assign({}, nullVendor),
        bp:             Object.assign({}, nullVendor),
        cpu:            Object.assign({}, nullVendor),
        gpu:            Object.assign({}, nullVendor),
    }
};

const vendorSuffix = (vendor: Vendor): string => {
    switch (vendor) {
        case Vendor.ali:
            return "ali"
        case Vendor.friend:
            return "bu"
        case Vendor.avito:
            return "avito"
        default:
            return "magaz"
    }
}

type ShopSettingsUpdateAction = PayloadAction<{
    settings: RawServerSettings
}>

const getBuyEventName = (item: ShopItem, vendor: Vendor) => `buy_${item.id}_${vendorSuffix(vendor)}`

export const shopSlice = createSlice({
    name: 'shopSlice',
    initialState,
    reducers: {
        updateShopSettings: (state, action: ShopSettingsUpdateAction) => {
            const settings = action.payload.settings
            console.log('settings', settings)
            // todo remove when price included in shopItem
            // сейчас цены подставляются внутрь захардкоженного итема, потом сделаем, чтобы все приходило с сервера

            state.availableItems = ShopItems.map((i) => ({
                    ...i,
                    price: {
                        shop:   +settings[`price${i.id}` as keyof RawServerSettings],
                        ali:    +settings[`price${i.id}ali` as keyof RawServerSettings],
                        friend: +settings[`price${i.id}bu` as keyof RawServerSettings],
                        avito:  +settings[`price${i.id}avito` as keyof RawServerSettings],
                    },
                    vendorsStock: {
                        shop:               +settings[`qty${i.id}magaz` as keyof RawServerSettings],
                        tradePlatform:      +settings[`qty${i.id}avito` as keyof RawServerSettings],
                        tradePlatformChina: +settings[`qty${i.id}ali` as keyof RawServerSettings],
                        used:               +settings[`qty${i.id}bu` as keyof RawServerSettings],
                    },
                    // @ts-ignore
                    buyBackPrice: +settings[`dealerPrice${ToTitleCase(i.id)}`]
                })
            )
            state.wallet = {
                name:           'кошелек',
                description:    'криптовалютный кошелек',
                icon:           walletIcon,
                price:          +settings.pricewall
            }

            state.defectChance = {
                shop:               +settings.brak,
                tradePlatform:      +settings.brakavito,
                tradePlatformChina: +settings.brakali,
                used:               +settings.brakbu
            }
        },
    }
});

export const buyItem = (
    item: ShopItem,
    vendor: Vendor,
    amount = 1,
): AppThunk => (dispatch, getState) => {
    const eventName = getBuyEventName(item, vendor)
    dispatch(
        updateFarm(
            eventName,
            (data) => ({
                ...data,
                [item.id]: +data[item.id as keyof RawServerData] + amount
            })
        )
    )
};

export const BuyWallet = (): AppThunk => (dispatch, getState) => {
    const eventName = 'buy_wallet'
    dispatch(
        updateFarm(
            eventName,
            (data) => ({
                ...data,
                wallet: 1
                // wallet: +data.wallet + 1000
            })
        )
    )
}

export const sellItem = (
    item: string,
    count = 1,
): AppThunk => (dispatch, getState) => {
    const eventName = `sell_${item}`
    dispatch(
        updateFarm(
            eventName,
            (data) => ({
                ...data,
                [item]: +data[item as keyof RawServerData] - count
            })
        )
    )
};

export default shopSlice.reducer;

export const {updateShopSettings} = shopSlice.actions;
