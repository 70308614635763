import styles from "./NFTView.module.css"
import {NFT} from '../../../app/slices/walletSlice'
import {HorizontalStatView} from '../../stats/view/StatView'
import React from 'react'

interface NFTViewProps {
    nft: NFT
}

export function NFTView(props: NFTViewProps) {
    return (
        <div className={styles.nftItem}>
            <div className={styles.nftItemImageConatainer}>
                <img src={props.nft.image} className={styles.nftItemImage} alt={''}/>
                <div className={styles.nftItemName}>{props.nft.name}</div>
            </div>

            <div className={styles.nftItemData}>
                <div><HorizontalStatView title={"Доход в час, E4:"} text={props.nft.income}/></div>
                <div><HorizontalStatView title={"Совместимость"} text={`${props.nft.compatibility}%`} /></div>
            </div>
        </div>
    )
}
