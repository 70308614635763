import styles from "./Navbar.module.css";
import React from "react";
import classNames from "classnames";

interface NavbarItem {
    icon: React.JSX.Element,
    name: string
}

interface NavbarProps {
    items: NavbarItem[],
    activeTab: number,
    onClick: (position: number) => void
}

export default function Navbar(
    props: NavbarProps
) {
    return (
        <div className={styles.navbar}>
            {
                props.items.map((item, i) => {
                    const classnames = classNames(
                        styles.navbar__item, props.activeTab === i ? styles.navbar__item_current : undefined
                    )
                    return (
                        <div key={`navbar${i}`} className={classnames} onClick={() => props.onClick(i)}>
                            <div className={styles.navbar__item__icon}>{item.icon}</div>
                            <div className={styles.navbar__item__text}>{item.name}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}
