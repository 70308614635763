import React from "react"
import {LocationState} from '../../../app/slices/locationSlice'
import styles from "./LocationDialog.module.css"
import classNames from 'classnames'
import {HorizontalStatView} from '../../stats/view/StatView'
import {Button} from '../../../components/view/Button'
import {CurrencyObject} from '../../../model/interfaces'

interface LocationDialogProps {
    location: LocationState
    onBuyNewLocation: () => void
}

export function LocationDialog(props: LocationDialogProps) {
    return (
        <div className={styles.locationDialog}>
            <div className={styles.locationDialogRow}>
                <HorizontalStatView title={"Текущая локация"} text={props.location.location}/>
            </div>
            <div className={styles.locationDialogRow}>
                <HorizontalStatView title={"Доступная локация"} text={props.location.nextLocation}/>
            </div>
            {
                Object.keys(props.location.upgradePrice).map((currency: string) => {
                    const value = props.location.upgradePrice[currency as keyof CurrencyObject]

                    if (value && value > 0) {
                        return <div key={currency} className={styles.locationDialogRow}>
                            <HorizontalStatView title={`Цена обновления, ${currency}`}
                                                text={value}/>
                        </div>
                    }
                })
            }

            <div className={styles.locationDialogRow}>
                <HorizontalStatView title={"Мин. баланс после обновления, me4ton"} text={props.location.minBalanceAfterUpgrade}/>
            </div>
            <div className={classNames(styles.dialogRow, styles.center)}>
                <Button text={"Переехать"} disabled={!props.location.upgradeAvailable}
                        onClick={() => {
                                if (props.location.upgradeAvailable) props.onBuyNewLocation()
                            }
                        }/>
            </div>
        </div>
    )
}
